.FilterList {
	max-width: 400px;
	border-top: 1px solid #aaa;
	padding-top: 10px;
	margin-top: 10px;

	.FilterDetail {
		padding: 5px 10px 5px 10px;
		transition: background 0.1s ease-in-out;
		border-radius: 3px;
	}

	.FilterDetail:hover {
		background: #eee;
	}

	.filterListHeader {
		animation-name: fadeInUp;
		animation-duration: 0.5s;
		animation-timing-function: ease-out;
	}

	.list-settings {
		float: right;
	}
}
