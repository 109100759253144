// @import 'components/color';

.StatusBulkEditDropdown {
	display: grid !important;
	grid-template-columns: 0.6fr 0.3fr;
	padding : {
		right: 4px !important;
		top: 0px !important;
		bottom: 0px !important;
	}

	.text {
		text-align: center;
		padding: {
			top: 11px !important;
			bottom: 11px !important;
			right: 2px !important;
		}
	}

	.dropdown.icon {
		padding: {
			right: 0.8em !important;
			left: 0.8em !important;
			top: 10px !important;
			bottom: 10px !important;
		}
		margin-right: 0 !important;
		background-color: rgba(0, 0, 0, 0.05) !important;
		height: 100% !important;
		width: 90% !important;
		position: absolute;
		right: 5px !important;
		top: 0px !important;

		> .icon {
			height: 100% !important;
			width: 2.57142857em !important;
			position: absolute;
			line-height: 1;
			top: 0;
			left: auto;
			right: 0;

			&:before {
				height: 100% !important;
				display: block;
				position: absolute;
				width: 100%;
				top: 10px;
				text-align: center;
				-webkit-transform: translateY(-50%);
				transform: translateY(-50%);
			}
		}
	}
	.menu.transition {
		padding: 0px;
		margin: 0px;

		.item {
			.label {
				border-radius: 0px;
				text-align: left !important;
				width: 100% !important;
				padding-top: 11px;
				padding-bottom: 11px;
				padding-left: 15px !important;
				background-color: #2185d0;
				color: #fff;
				font-size: inherit !important;
				border-bottom-left-radius: 0 !important;
				cursor: default;
			}
			.ui.button {
				padding: 10px;
				margin: 0px;
				border-radius: 0px;
				width: 100% !important;
				text-align: left;
				opacity: 0.95;
			}
		}
	}
}
