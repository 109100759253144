.dropCard{
    width: 500px!important;
    height: 500px!important;
}
.dragCard{
    width: 320px !important;
    height: 320px !important;
}

.ui.cards.dragCards {
    display: grid;
    grid-template-columns: auto auto;
    margin:unset;
    flex-wrap: unset;
}