@import 'components/cursors';

.SelectionBox {
	font-size: 22px;
	margin-left: -6px;
	padding-left: 5px;


	.icon[data-selectable='true'] {
		&[data-checked='false'] {
			cursor: $cursorPlus;
		}
		&[data-checked='true'] {
			cursor: $cursorMinus;
		}
	}
}
