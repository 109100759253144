@import 'components/colors';

.DateRangesInput {
	display: flex;
	.expandButton {
		margin-top: 20px !important;
		.button {
			background-color: $blueButton !important;
			&:hover {
				background-color: $blueButtonHover !important;
			}
			color: $whiteText;

			padding: {
				left: 0 !important;
				right: 0 !important;
			}
		}
	}
	.inputsGroup {
		width: 100%;
		margin-left: 10px;

		.fields {
			margin-top: 3px !important;
		}
	}
}
