.SetListTable {
	padding-left: 15px;
	display: block;

	.SetListHeader {
		min-width: 150px;
		width: 30%;
		display: inline-block;
		.Artist {
			display: inline-block;
			white-space: nowrap;
			width: calc(100% - 32px);
			text-overflow: ellipsis;
			overflow: hidden;
			font-weight: 700;
		}
	}

	.divider {
		margin: 0 0 8px 0;
		border-top: 1px solid rgb(232, 233, 233) !important;
		border-top: 1px solid rgb(232, 233, 233) !important;
	}

	.close,
	.open {
		color: #6a6a6a;
		cursor: pointer;
		margin-right: 10px;
		float: right;
	}
	.TableContainer {
		min-width: 300px;
		width: 67%;
		display: inline-flex;

		table {
			table-layout: fixed;
			width: 100%;
		}
		td {
			min-width: 150px;
			padding: 9px 15px !important;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.table {
			th {
				padding-bottom: 5px;
			}
		}
	}
}
