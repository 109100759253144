.FilterDetail {
	position: relative;
	margin: 5px 0px;
	transition: opacity 0.3s ease-in-out;
	min-width: 200px;

	&.disabled .line {
		opacity: 0.4;
	}

	.icon.close,
	.icon.edit {
		height: 17px;
		border-radius: 4px;
		position: absolute;
		top: 4px;
		transition: opacity 0.1s ease-in-out, background-color 0.1s ease-in-out;
		cursor: pointer;
		opacity: 0;
	}

	.icon.close {
		right: 2px;
	}

	.icon.edit,
	.icon.edit.disabled {
		right: 18px;

		transition: opacity 0.1s ease-in-out, background-color 0.1s ease-in-out;
		cursor: pointer;
		opacity: 0 !important;
	}

	.icon.close:hover,
	.icon.edit:hover {
		background-color: rgba(215, 215, 215, 1);
	}

	.line {
		width: 90%;
		word-wrap: break-word;
	}

	.lineEdit {
		width: 100%;
	}

	.Action:hover {
		cursor: pointer;
	}

	&:hover {
		.icon.close,
		.icon.edit {
			opacity: 1 !important;
		}
		.icon.edit.disabled {
			opacity: 0.5 !important;
		}
	}

	&:not(.FilterDetailEdit) span {
		&.block {
			padding: {
				left: 5px;
				right: 5px;
				top: 1px;
				bottom: 1px;
			}
			&:not(:last-of-type) {
				margin-right: 5px;
			}

			background-color: #a8a8a860;
			border: {
				radius: 4px;
			}
		}
	}
}

.FilterDetailEdit {
	background-color: rgba(215, 215, 215, 0.75);
}
