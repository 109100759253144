@import 'components/colors';
.SidebarLayout {
	& > main {
		margin-left: 300px;
	}

	.Sidebar {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		width: 300px;
		z-index: 24;

		background: $blueContainer;

		.logo.small {
			display: none;
		}

		.UserDropdown h4.full {
			display: block;
		}

		.UserDropdown h4.small {
			display: none;
		}
	}

	&.collapsed {
		> main {
			margin-left: 60px;
		}

		.Sidebar {
			width: 60px;

			.logo.big {
				display: none;
			}

			.logo.small {
				display: block;
			}

			.UserDropdown h4.full {
				display: none;
			}
			.UserDropdown h4.small {
				display: block;
				margin-top: 0;
			}
		}
	}
}
