.CustomDateInput {
	&.hasTimeInput {
		display: flex;
		margin-bottom: 10px;
		.dateInput,
		.timeInput {
			margin: 0 !important;
		}
		.dateInput {
			width: calc(50% - 5px);
			margin-right: 10px !important;
		}
		.timeInput {
			width: calc(50% - 5px);
		}
	}

	input {
		&:hover {
			border: {
				style: solid !important;
				color: rgb(175, 175, 175) !important;
				width: 1px;
				overflow: hidden !important;
			}
		}
	}
}

div.ui.left.flowing.popup.transition.visible.scale.visible.transition {
	z-index: 23;

	&.bottom,
	&.top {
		&.animating,
		&.out,
		&.in {
			z-index: 0;
		}
	}
}
