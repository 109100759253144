.FilterEntry {
	.enabler {
		position: absolute;
		left: 10px;
		top: 6px;
	}
	.line {
		padding-left: 30px;
	}
}
