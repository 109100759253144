@import 'components/colors';
.PasswordReset,
.PasswordSuccess {
	padding-top: 100px;
	.whitebox {
		position: relative;
		margin: 0 auto;
		width: 450px;
		min-height: 400px;
		animation-name: slideUp, slideUpBorder;
		animation-duration: 1s;
		animation-timing-function: ease-in-out;
		background-color: #fff;
		padding: 50px 30px 15px 30px;
		box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
		border-radius: 5px;
	}
	.centralMessage {
		max-width: 600px;
		margin: auto;
	}

	.logo {
		text-align: center;
		margin-bottom: 50px;
		img {
			width: 400px;
		}
	}
	.header {
		text-align: center;
		color: $darkBlueText;
		opacity: 0.8;
	}
	.icon {
		color: $darkBlueText;
		opacity: 0.8;
	}
}
