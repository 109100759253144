@import 'components/colors';

// widths of sticky columns
// widths have to be the same as in tableWidthsConfig
$selectionColumnWidth: 40px;
$collaborationWidth: 40px; // width: 55px when viewing users is implemented
$stateInfoWidth: 40px;

::selection {
	background: $highlightTextBackground;
	color: $whiteText;
}

.ui.table.VTContainer {
	position: relative;
	font-size: 0.9em;
	border-radius: 0.28571429rem;

	&.grey {
		border-top: 0.19em solid #767676;
	}

	&.blue {
		border-top: 0.2em solid #2185d0;
	}
}

.VTList {
	overflow-y: scroll !important;
}

.Generic {
	.tableWrapper {
		box-shadow: 0 0 6px 2px #ddd;
		bottom: 20px;
		top: 30px;
		left: 0px;
		right: 0px;
		overflow: hidden;
		margin-top: 20px;
		padding: 0 0 3px 0;
		position: absolute;
		outline: none;
	}
}

.onReachBottomRow {
	height: 83px;
	left: 0;
	position: absolute;
	bottom: 0;
	width: 100%;
}

.VTGrid {
	outline: 1px solid #5c7080;
}

.VTHeader {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	border-radius: 0.28571429rem;
	display: flex;
	flex-flow: row nowrap;
	height: 100%;
	align-items: stretch;
	overflow: hidden;
	box-sizing: border-box;
	border-bottom: 1px solid rgba(34, 36, 38, 0.1);
	font-weight: bold;

	span.multisort-group {
		display: inline-block;
	}
	.VTHeaderCell {
		border-right: 1px solid #2224261a;
		user-select: none;
		background-color: $headerCell;

		&:hover {
			background-color: $headerCellHover;
		}
		&.sorted {
			background-color: $headerCellSorted;
			&:hover {
				background-color: $headerCellSortedHover;
			}
		}
		&:last-of-type {
			border-right: 0;
		}
		&:not(:hover) {
			.SortIcon.no-value {
				visibility: hidden !important;
			}
		}
		.VTCellContent {
			display: inline-flex;

			.header-label {
				flex-grow: 1;
				text-overflow: ellipsis;
				overflow: hidden;
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}

.VTRow {
	display: flex;
	flex-flow: row nowrap;
	height: 100%;
	align-items: stretch;
	box-sizing: border-box;

	.VTCell {
		transition: {
			duration: 0.15s;
			property: background-color;
		}
		background-color: $defaultRow;

		&:hover .redirect-icon {
			cursor: pointer;
			visibility: visible;
		}
	}

	&:hover .VTCell {
		background-color: $hoverRow;
	}

	&.focused {
		.VTCell {
			background-color: $focusedRow;
		}

		&:hover .VTCell {
			background-color: $focusedHoverRow;
		}
	}

	&.unselectable {
		.VTCell {
			color: $unselectableRowText;
		}

		&:hover .VTCell {
			color: $unselectableHoverRowText;
		}
	}

	&.selected,
	.active {
		.VTCell {
			background-color: $selectedRow;
		}

		&:hover .VTCell {
			background-color: $selectedHoverRow;
		}
	}

	&.focused.selected {
		.VTCell {
			background-color: $focusedSelectedRow;
		}

		&:hover .VTCell {
			background-color: $focusedSelectedHoverRow;
		}
	}

	&.preselected {
		&:not(.focused):not(.selected):not(.unselectable) .VTCell {
			background-color: $preselectedRow !important;
		}

		&.focused:not(.selected):not(.unselectable) .VTCell {
			background-color: $preselectedFocusedRow !important;
		}
	}

	&.predeselected {
		&:not(.focused).selected .VTCell {
			background-color: $predeselectedRow !important;
		}

		&.focused.selected .VTCell {
			background-color: $predeselectedFocusedRow !important;
		}
	}
}

// some cells that are extendable might overflow slightly and cause a scrollbar to appear
.events,
.location-search,
.organizer-search,
.history {
	.VTCell {
		overflow-y: hidden !important;
	}
}

.VTCell,
.VTHeaderCell {
	flex-grow: 1;
	box-sizing: border-box;
	position: relative;

	-ms-flex-align: center;
	align-items: center;
	display: -ms-flexbox;
	display: flex;

	.VTCellContent {
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.VTCell {
	border-right: 1px solid #2224261a;
	border-bottom: 1px solid #2224261a;
	overflow: overlay; // only chrome
}

@-moz-document url-prefix() {
	.VTCell {
		overflow-y: auto;
	}
}

.VTCellContent {
	overflow: hidden;
	padding: 0.4em 0.6em;
	width: 100%;
	.redirect-to-select {
		.redirect-icon {
			visibility: hidden;
			margin: {
				left: 10px;
				right: 0;
			}
			color: $blueText;
		}
	}
}

.VTColumnResizer {
	cursor: col-resize;
	position: absolute;
	right: -3px;
	top: 0;
	height: 100%;
	background-color: $blueBorder;
	opacity: 0;
	width: 6px;
	z-index: 20;
}

.VTColumnResizer:hover {
	opacity: 0.1;
}

.VTColumnResizerActive {
	opacity: 0.5 !important;
	transition: opacity 200ms;
}

.VTNoItemsLabel {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 1em;
	color: #bdbdbd;
}

// only relevant for company with > 5 years => deecoob
.VTRow {
	.VTCell.statistic\.eventCountPerYear,
	.VTCell.customerLocations,
	.VTCell.customerOrganizers {
		overflow: overlay;
	}
}

.VTCellContent.leaflet {
	margin: auto;
}

// used in My Searches and My Selections for Buttons inside Table
.VTCell.actions {
	padding: 0 10px;

	.VTCellContent {
		padding: 0;

		.button:last-child {
			margin: 0;
		}
	}
}

// sticky columns
.VTCell,
.VTHeaderCell {
	&.selectionColumn,
	&.collaboration,
	&.stateInfo {
		position: sticky;
		z-index: 20;
		left: 0;

		.VTCellContent {
			text-overflow: clip !important;
		}
		.VTColumnResizer {
			cursor: inherit;
			opacity: 0 !important;
		}

		&:first-of-type {
			left: 0;
		}
	}
	&.selectionColumn ~ &.collaboration {
		left: $selectionColumnWidth;
	}
	&.selectionColumn ~ &.stateInfo {
		left: $selectionColumnWidth;
	}
	&.collaboration ~ &.stateInfo {
		left: $collaborationWidth;
	}
	&.selectionColumn ~ &.collaboration ~ &.stateInfo {
		left: $selectionColumnWidth + $collaborationWidth;
	}
}
