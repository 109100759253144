@import 'components/colors';
$labelFontMargin: 1em;
$iconFontSize: 1.25em;

.action {
	&-negate-button {
		color: white !important;

		&.positive {
			background-color: $greenButton !important;
		}
		&.negative {
			background-color: $redButton !important;
		}

		&:focus {
			border-right-color: transparent !important;
		}
	}

	&-icon {
		font-size: $iconFontSize !important;
	}

	&-label {
		padding: 0 !important;
		cursor: initial;

		&::before {
			border-width: 0 !important;
		}

		> span {
			margin-left: $labelFontMargin;
			cursor: pointer;
		}
	}

	&-select,
	&-label {
		width: 100%;
		border: {
			top-left-radius: 0 !important;
			bottom-left-radius: 0 !important;
		}
	}
}

.positive ~ .action-label::before {
	background-color: $greenButton !important;
}
.button:hover .positive ~ .action-label::before {
	background-color: $greenHoverButton !important;
}
.negative ~ .action-label::before {
	background-color: $redButton !important;
}
.button:hover .negative ~ .action-label::before {
	background-color: $redHoverButton !important;
}
