@import 'components/colors';
.EventDetail {
	.heading {
		.break {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 80%;
			margin: 0;
			padding-bottom: 8px;
		}
	}

	.tab-sub-header {
		margin-bottom: 10px;
		display: flex;
		align-items: stretch;

		.source-meta {
			flex: {
				grow: 0;
				shrink: 1;
			}

			line-height: 1em;
			text-overflow: ellipsis;
			margin-left: 5px;
		}

		.ColoredIdSwitcher {
			flex: {
				grow: 1;
				shrink: 0;
			}
		}

		.source-meta,
		.ColoredIdSwitcher {
			overflow: hidden;
			white-space: nowrap;
		}
	}

	.clickable-id {
		cursor: pointer;

		&:hover {
			color: $grayText;
		}

		&.shown {
			color: $blueText;

			&:hover {
				color: $blueTextHover;
			}
		}
	}

	.colored-idx {
		cursor: pointer;

		&:hover {
			filter: brightness(40%);
			opacity: 0.8;
		}

		// look into EventDetail for other clickable-id
	}

	.pdfViewer {
		border: 5px solid #888;
		width: 100%;
		// 30px === .tab-sub-header height + margin
		height: calc(100% - 30px);
	}

	.descriptions-frame {
		// 30px === .tab-sub-header height + margin
		height: calc(100% - 30px);
		overflow-y: auto;
	}

	.draggingPlaceholder {
		padding-top: 200px;
		text-align: center;
		font-size: 1.5em;
		line-height: 1.5em;
	}
}
