.WorkflowLayer {
	height: 100%;

	.Container.keyblock {
		.tableWrapper {
			user-select: none;
		}
	}

	.Container {
		display: inline;
	}
}
