@import 'components/colors';
.ui.dropdown.RatingInput {
	border: 1px solid transparent !important;
	&:focus-within {
		//avoid that focusing stays when star element inside selected but
		// other element selected
		border: 1px solid inherit !important;
	}
	&:focus {
		border: 1px solid $borderFocus !important;
	}
}