.LeafletList {
	.LeafletBox {
		margin-bottom: 20px;

		h3.leafletDescription {
			margin: 5px 0 5px 0;
		}

		&:hover {
			filter: invert(6%);
			cursor: pointer;
		}
	}
}
