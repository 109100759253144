@import 'components/colors';
.InfoBlock {
	margin: 20px 10px 20px 20px;

	h3 {
		max-width: 90%;
		display: inline-block;
		margin: 0 0 1rem;

		.sub-heading {
			font-weight: 100;
			font-size: 1rem;
			opacity: 0.5;
		}
	}

	.open,
	.close {
		color: $blueText;
		cursor: pointer;
		white-space: pre;
		float: right;
		margin-right: 20px;
		font-size: larger;
	}

	.clickable {
		cursor: pointer;
		&:hover {
			opacity: 0.8;
		}

		&.heading-gray {
			color: gray !important;
		}
	}

	.rotating {
		animation: rotation 1s infinite linear;
	}
	@keyframes rotation {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}

	.idxs {
		white-space: nowrap;
		user-select: none;
		span.streched {
			display: inline-flex !important;
			min-width: 280px !important;
			align-items: stretch !important;
			.idx {
				flex-grow: 1 !important;
				text-align: center !important;
			}
		}

		.forward,
		.backward {
			width: 0.9em !important;
			text-align: center;
			display: inline-block;
			.button {
				width: 0.8em;
				height: 1.6em;
				padding: 0 !important;
				background-color: transparent;
				.icon {
					display: inline;
					color: black;
					font-size: 1.2em;
				}
			}
		}
		.forward .button {
			margin-left: 0.1em !important;
		}
		.backward .button {
			margin-right: 0.1em !important;
		}
	}

	small.id {
		white-space: nowrap;
		margin: 0 3px 0 0;
	}
}
