@import 'components/colors';
.ToCustomLeaflet {
	.label {
		width: 100% !important;
		padding: {
			top: 11px;
			bottom: 11px;
			left: 15px !important;
		}
		background-color: $lightBlueButton2;
		color: #fff;
		font-size: inherit !important;
		border: {
			bottom: {
				left-radius: 0 !important;
				right-radius: 0 !important;
			}
		}
	}
	.icon {
		float: right;
		margin: 0;
	}
}

// placed extra to allow higher spicificity
div.menu .ToCustomLeaflet .menu.leaflet-menu.scrolling {
	background-color: $lightBlueContainer;
	margin: inherit !important;
	border-top: {
		left-radius: 0 !important;
		right-radius: 0 !important;
	}
}
