@import 'components/colors';
.Header {
	.line {
		&.small {
			font-size: small !important;
		}
		.creator {
			display: inline-block;
		}
		.icons {
			display: table-row;
			float: right;
			font-size: medium;
			div {
				display: table-cell;
				width: 28px;
				.labeled-icon .icon-label {
					background-color: $lightBlueButton;
					margin: 0 0 7px -14px;
				}
			}
		}
		.description {
			float: right;
			display: inline-block;
		}
		.items {
			display: inline-block;
			font-weight: 600;
		}

		@keyframes spin {
			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
		.sync.alternate.icon {
			width: 1em;
			height: 1em;
			line-height: 1em;
			color: $blueText;
			animation-name: spin;
			animation-duration: 3s;
			animation-timing-function: linear;
			animation-iteration-count: 1;
			margin: 0;
		}
	}
	.line:first-of-type {
		padding-bottom: 2px;
	}
}

.items {
	display: inline-block;
	font-weight: 600;
}
