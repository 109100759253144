@import 'components/colors';

.ImageUploader {
	height: 100%;
	padding-bottom: 20px;
	padding-right: 5px;
	overflow-y: scroll;

	.picture-uploader.mandatory {
		background-color: $greyContainer;
		border: 1px solid $darkGrayContainer;
		border-radius: 6px;
		min-height: 170px;
		padding: 10px;
		margin-bottom: 10px;
		&:hover {
			border-color: $borderHover;
		}
		&.vertical-text {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			font-size: 1.4em !important;
			font-weight: bold;
			text-align: center;
			padding-bottom: 0px;
		}
		img {
			width: 100% !important;
			margin-bottom: 5px;
		}
		.pdfViewer {
			width: 100% !important;
			height: 50vh;
		}
		.footer {
			display: flex;
			margin: 10px;
			.file-name {
				font-size: 1.3em !important;
				flex: {
					grow: 1;
					shrink: 0;
				}
			}
			.update-image {
				display: flex;
				flex-direction: column;
				height: 60px;
				width: 400px;
				font-size: 1.2rem;
				border-radius: 10px;
				transition: all 1s ease-in-out;
				position: relative;
				&:hover {
					height: 100px;
					width: 500px;
					border: 1px dashed $borderUploadImage;
				}
				.image {
					margin-top: 5px;
					align-self: center;
					z-index: -0;
					opacity: 0.7;
				}
				.release-text {
					position: absolute;
					margin-top: 5px;
					width: 400px;
					top: 30px;
					align-self: center;
				}
			}
			.DeleteIcon {
				flex: {
					grow: 0;
					shrink: 1;
				}
			}
		}
	}
	.url-input {
		margin-bottom: 20px;
		width: 100%;
		&.mandatory input {
			border: 1px solid $darkGrayContainer;
			border-radius: 6px;
			&:hover {
				border-color: $borderHover;
				&:focus {
					border: 1px solid $borderHoverFocus;
				}
			}
			&:focus {
				border: 1px solid $borderFocus;
			}
		}
		.icon {
			float: right;
		}
	}
}
