.LeafletItem {
	.ui.grey.segment,
	.ui.blue.segment.current {
		min-height: 280px;
		.createdAt {
			margin-bottom: -3px;
			position: absolute;
			bottom: 15px;
		}
		.footer {
			display: flex;
			position: absolute;
			bottom: 45px;

			.ui.button,
			.ui.buttons .button,
			.ui.buttons .or {
				min-width: 80px;
			}
		}

		a.ui.basic.label:hover {
			cursor: default;
			border: 1px solid rgba(34, 36, 38, 0.15);
			color: rgba(0, 0, 0, 0.87);
		}
	}
	.ui.blue.segment.current {
		min-height: 282px;
	}
}
