@import 'components/colors';

.viewingStatus {
	&.lockedByMe {
		color: $greenText;
		transform: rotate(-20deg); // use another visual variable to code lockedByMe (color blindness)
	}
	&.lockedByOtherUsers {
		color: $redText;
	}
}
