.sticky {
	// make sticky to always be visible
	position: sticky;
	top: 0;
	z-index: 22;

	// visual indication
	box-shadow: 1px 2px 5px #ddd;
	background: rgb(221, 221, 221) !important;
	margin-bottom: 6px;
}
