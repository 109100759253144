.labeled-icon {
	position: relative;
	.icon {
		position: relative;
		cursor: inherit;
	}

	.icon-label {
		position: absolute;

		left: 1.5em;
		bottom: 35%;

		$height: 1.4em;
		min-width: $height;
		min-height: $height;
		line-height: $height;

		border: {
			style: solid;
			width: 1px;
			color: black;
			radius: 500rem;
		}

		padding: 0 2px;
		text-align: center;
		font: {
			size: 0.7em;
			weight: 900;
		}

		background-color: #fff;
		color: black;
		cursor: inherit;
	}
}
