.merged-item {
	position: relative;

	.content {
		display: inline-block;
	}

	.id-markers {
		position: absolute;
		overflow: hidden;
		left: 0;
		top: 1.2em;
		width: 100%;
		height: auto;
		line-height: 0.3em;

		svg.id-marker {
			vertical-align: top !important;
			width: 0.6em !important; //if changing this also change min-width computation in MergedItem.js
			height: 0.2em !important;

			rect {
				width: 80% !important;
				height: 100% !important;
			}
		}

		.overflow {
			display: inline;
			position: absolute;
			top: -0.35em;
			font-size: 1.3em;
			color: black;
		}
	}
}
