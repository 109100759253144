@import 'components/colors';
.Navi {
	display: flex;
	justify-content: space-between;

	a {
		width: 31%;
	}

	a.active .button {
		background: $darkBlueButton;
	}
	.ui.blue.button.activeNavButton {
		background: $darkBlueButton;
	}

	.button:last-child {
		margin-right: 0 !important;
	}
}
