@import 'components/colors';

.SortIcon {
	position: relative;
	&:hover {
		color: $blueText;
		font-weight: bolder;
	}
	&.no-value {
		opacity: 0.5;
	}
	.icon {
		cursor: pointer;
		font-size: 1.4em;

		width: 20px;
		height: 20px;
		margin-right: -1px;
	}
	.icon-label {
		position: absolute;

		left: 1.5em;
		bottom: 50%;

		$height: 1em;
		min-width: $height;
		min-height: $height;
		line-height: $height;

		font: {
			size: 0.8em;
		}

		cursor: inherit;
	}
}

.sortIcon-wrapper {
	position: relative;

	> :first-child {
		font-size: 1.1em;
		width: 22px;
	}

	.SortIcon {
		position: absolute;
		top: 1em;
		left: 0em;
	}
}
