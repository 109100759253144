$deecoobBlue: #3f72af;
$deecoobDarkBlue: #112d4e;

// text / icon ////////////////////////////////////////
$blueText: $deecoobBlue;
$blueTextHover: #728eb1;
$darkBlueText: $deecoobDarkBlue;
$lightBlueText: #67798d;
$blueInfoText: #7799c2;

$greenText: #448a55;

$redText: #9f3a38;

$orangeIcon: #e67e22;

$grayText: #7c7c7c;
$darkGrayText: #444;
$lightGrayText: #b6b6b6;

$whiteText: #fff;

// button ///////////////////////////////////////////
$blueButton: $deecoobBlue;
$blueButtonHover: #39679f;

$darkBlueButton: $deecoobDarkBlue;

$lightBlueButton: #3f72af88;
$lightBlueButton2: #2185d0;

$blueButtonEnabled: $blueButton;
$blueButtonEnabledHover: #728eb1;
$blueButtonNotEnabled: #fff;
$blueButtonNotEnabledHover: #afbed1;

$greenButton: #0e7c27;
$greenHoverButton: #2d8a42;
$drakGreenButton: #06631c;

$redButton: #930f0f;
$redHoverButton: #9e2e2e;

$grayButton: #aaaaaa;
$grayButtonHover: #bbbbbb;

$lightGrayButton: #cecece;
$lightGrayButtonHover: #9bacc3;

// container //////////////////////////////////////////
$blueContainer: $deecoobBlue;
$lightBlueContainer: #b1c4d3;
$blueShimmerContainer: #eef7fd;

$redContainer: #e6000033;
$redShimmerContainer: #ffefef;

$greyContainer: #e0e1e2;
$greyContainerHover: #bbbbbb;
$whiteContainer: #fff;
$darkGrayContainer: #444444;

// table row //////////////////////////////////////////
$defaultRow: #fff;

$headerCell: #f0f1f3;
$headerCellHover: #e3e5e7;
$headerCellSorted: #cacdcf;
$headerCellSortedHover: #bcbcbd;

$hoverRow: #f2f2f2;
$focusedRow: #d6e9ff;
$focusedHoverRow: #c9e1ff;
$selectedRow: #cccccc;
$selectedHoverRow: #b2b2b2;
$focusedSelectedRow: #8caacf;
$focusedSelectedHoverRow: #789cc7;
$preselectedRow: #e5e5e5;
$preselectedFocusedRow: #bbdaff;
$predeselectedRow: #fffbe5;
$predeselectedFocusedRow: #fff4b2;

$unselectableRowText: #9b9b9b;
$unselectableHoverRowText: #737373;
$highlightTextBackground: #0061f2b7;

// border /////////////////////////////////////////////
$blueBorder: #1f4b99;
$lightBlueBorder: #85b7d9;
$borderFocus: $lightBlueBorder;
$thickBlueBorder: #2185d0;

$borderError: #82000080;
$thickRedBorder: #d02121;
$thickLightRedBorder: #d021216c;

$borderGray: #22242626;
$borderHover: #22242659;
$borderGrayFocus: #0000009d;
$borderUploadImage: #000000e0;
$borderHoverFocus: $borderHover;

// input //////////////////////////////////////////////
$errorInput: #ff000040;
$ownErrorInput: #ff00008c;
$changedInput: #eeff0040;
