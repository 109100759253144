.OrganizerData,
.LocationData {
	.address {
		margin-top: 10px !important;

		$gap: 15px;

		// street and house number next to each other
		$housNumberWidth: 130px;
		&.street {
			width: calc(100% - #{$housNumberWidth + $gap}) !important;
			margin-right: $gap !important;
		}
		&.houseNumber {
			width: $housNumberWidth !important;
		}

		// postal code and city next to each other
		$postalCodeWidth: 100px;
		&.city {
			width: calc(100% - #{$postalCodeWidth + $gap}) !important;
		}
		&.postalCode {
			width: $postalCodeWidth !important;
			margin-right: $gap !important;
		}
	}

	.ui.selection {
		width: 100%;
	}
}
