@import 'components/colors';
@import 'components/fonts';

@keyframes slideLeft {
	from {
		left: 10px;
		opacity: 0;
	}
	to {
		left: 0;
		opacity: 1;
	}
}
@keyframes fadeInUp {
	from {
		top: 20px;
		opacity: 0;
	}
	to {
		top: 0;
		opacity: 1;
	}
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

body {
	font-size: 14px;
	margin: 0;
	height: auto;
	background-color: #fff;
	font-family: $standardFontFamily;
}

.ui.header {
	font-family: $standardFontFamily;
}

.ui.primary.button,
.ui.primary.buttons .button {
	background-color: $blueButton;
}

.ui.primary.button:hover,
.ui.primary.buttons .button:hover {
	background-color: $blueButtonHover;
}

.ui.grey.button,
.ui.grey.buttons .button {
	background-color: $grayButton;
}

.ui.grey.button:hover,
.ui.grey.buttons .button:hover {
	background-color: $grayButtonHover;
}

.withMargin > * {
	margin: 0 0.25rem !important;
}

svg.loader {
	position: absolute;
	left: calc(50% - 50px);
	top: calc(50% - 50px);
}

a {
	color: $blueText;
}

a:hover,
a:focus {
	color: $darkBlueText;
}

.ui.blue.button,
.ui.blue.buttons .button {
	background-color: $blueButton;
}

.ui.blue.button:hover,
.ui.blue.buttons .button:hover,
.ui.blue.button:focus,
.ui.blue.buttons .button:focus {
	background-color: $darkBlueButton;
}

main {
	height: 100vh;
}

/*
 *  Clearfix
 */
.clearfix:before,
.clearfix:after {
	content: ' '; /* 1 */
	display: table; /* 2 */
}

.clearfix:after {
	clear: both;
}
