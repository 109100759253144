.LeafletGrid {
	position: absolute;
	bottom: 20px;
	top: 30px;
	left: 0;
	right: 0;

	box-shadow: -1px -1px 0 0 #ddd;
	margin-top: 20px;
	padding: 0 0 10px 0;
	width: 100% !important;

	.ui.container.grid {
		width: 100% !important;
	}
	.ui.grid > [class*='five wide'].column {
		width: 33.33% !important;
	}
	.ui.grid > .column:not(.row) {
		padding-left: 0;
	}

	.five.wide.column:nth-of-type(3n + 0) {
		padding-right: 0;
	}
}
