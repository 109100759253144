@import 'components/colors';
.multisort-group {
	.multisort {
		display: inline-block;

		&.active {
			color: $blueText;
		}

		&:first-child {
			margin-left: 1em;
		}

		.custom:hover {
			opacity: 0.7;
			cursor: pointer;
		}

		.SortIcon.no-value {
			visibility: hidden;
		}
	}
}
