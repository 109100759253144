.RightSidebarLayout .sidebar {
	position: fixed;
	top: 0;
	right: 0;

	width: 400px;
	height: 100vh;
	padding: 20px;

	background: #ddd;
}

.RightSidebarLayout .main {
	padding-right: 400px;
	height: 100vh;
}
