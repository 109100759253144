.SetListTab {
	height: 100% !important;

	.SetListContainer {
		height: calc(100% - 43px);
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.SetListToolArea {
		// match margin-bottom of previous element (Tab)
		padding-bottom: 14px;
	}
}
