@import 'components/colors';

.CreateUpdateGeneric {
	width: 520px !important;
	.sub-heading {
		font-weight: 100;
		font-size: 1rem;
		opacity: 0.5;
	}
	td:first-of-type {
		width: 30px !important;
	}

	td > .ui.input {
		width: 100%;

		&.mandatory input {
			border: 1px solid $darkGrayContainer;
			border-radius: 6px;
			&:hover {
				border-color: $borderHover;
			}
			&:focus {
				border: 1px solid $borderFocus;
				&:hover {
					border: 1px solid $borderHoverFocus;
				}
			}
		}
	}

	.ui.input.address:not(:first-of-type) {
		margin-top: 10px;
	}

	.ui.address {
		&.selection.dropdown.mandatory {
			border: 1px solid $darkGrayContainer;
			border-radius: 6px;
			&:hover {
				border-color: $borderHover;
				&:focus {
					border: 1px solid $borderHoverFocus;
				}
			}
			&:focus {
				border: 1px solid $borderFocus;
			}
		}
		&.noRegions {
			color: $lightGrayText;
		}
	}

	.isUpdated:not(.BlocksInput):not(.error):not(.ownError):not(.ui.dropdown) input {
		background-color: $changedInput !important;
	}

	.isUpdated.ui.dropdown {
		background-color: $changedInput !important;
	}

	.BlocksInput {
		border: {
			color: $borderGray !important;
			style: solid;
			width: 1px;
		}
		margin-bottom: 0;

		&.isUpdated:not(.error) {
			background-color: $changedInput !important;
		}

		&.error {
			background-color: $errorInput !important;
		}

		&.isUpdated .block {
			&.error input {
				background-color: $errorInput !important;
			}
		}
	}

	.redirect-icon {
		color: $blueText;
	}
}

.CreateUpdateGenericTrigger.icon:not(.button) {
	color: $blueText;
}
