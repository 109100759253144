$cursorPlus: url('data:image/svg+xml;utf8,
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 540 511.991" style="enable-background:new 0 0 511.991 511.991;" xml:space="preserve" width="19px" height="19px">
    <g>
        <g xmlns="http://www.w3.org/2000/svg">
            <path d="M423.543,323.105l-320-320c-3.051-3.051-7.637-3.925-11.627-2.304c-3.989,1.643-6.592,5.547-6.592,9.856v469.333    c0,4.501,2.837,8.512,7.083,10.048c4.224,1.515,8.96,0.192,11.84-3.285l85.205-104.128l56.853,123.179    c1.792,3.883,5.653,6.187,9.685,6.187c1.408,0,2.837-0.277,4.203-0.853l74.667-32c2.645-1.131,4.715-3.285,5.76-5.973    s0.939-5.696-0.277-8.32l-57.024-123.52h132.672c4.309,0,8.192-2.603,9.856-6.592S426.593,326.156,423.543,323.105z" stroke="%23000000" stroke-width="24px" fill="%23ffffff" />
            <path d="M420,0 h10v90h90v10h-90v90h-10v-90h-90v-10h90v-90z"  stroke="%23000000" stroke-width="20"/>
        </g>
    </g>
    </svg> '),
	auto;

$cursorMinus: url('data:image/svg+xml;utf8,
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 540 511.991" style="enable-background:new 0 0 511.991 511.991;" xml:space="preserve" width="19px" height="19px">
    <g>
        <g xmlns="http://www.w3.org/2000/svg">
            <path d="M423.543,323.105l-320-320c-3.051-3.051-7.637-3.925-11.627-2.304c-3.989,1.643-6.592,5.547-6.592,9.856v469.333    c0,4.501,2.837,8.512,7.083,10.048c4.224,1.515,8.96,0.192,11.84-3.285l85.205-104.128l56.853,123.179    c1.792,3.883,5.653,6.187,9.685,6.187c1.408,0,2.837-0.277,4.203-0.853l74.667-32c2.645-1.131,4.715-3.285,5.76-5.973    s0.939-5.696-0.277-8.32l-57.024-123.52h132.672c4.309,0,8.192-2.603,9.856-6.592S426.593,326.156,423.543,323.105z" stroke="%23000000" stroke-width="24px" fill="%23ffffff" />
            <path d="M330,90 h190 v10 h-190z"  stroke="%23000000" stroke-width="20"/>
        </g>
    </g>
    </svg> '),
	auto;
