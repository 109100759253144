.Collapse {
	padding: 20px;

	font-size: 17px;
	color: rgba(255, 255, 255, 0.7);
	cursor: pointer;
	transition: all 0.1s ease-in-out;
}

.Collapse:hover {
	background: rgba(0, 0, 0, 0.2);
}
