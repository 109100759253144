.GraySidebarLayout {
	position: relative;

	&.collapsed {
		.sidebar:hover,
		.sidebar.keep {
			-webkit-transition: width 200ms ease-in-out 150ms;
			transition: width 200ms ease-in-out 150ms;
			width: 400px;
			position: absolute;
			z-index: 23;
			overflow-y: scroll;
			overflow-x: hidden;
		}

		.sidebar.keep + .collapser:hover,
		.sidebar:hover + .collapser {
			-webkit-transition: left 200ms ease-in-out 150ms;
			transition: left 200ms ease-in-out 150ms;
			left: 385px;
			z-index: 24;
		}

		.sidebar {
			width: 20px;
			overflow-y: hidden;
			overflow-x: hidden;
			z-index: 23;
			-webkit-transition: width 200ms ease-in-out 150ms;
			transition: width 200ms ease-in-out 150ms;
			position: absolute;
		}
		.main {
			padding-left: 20px;
		}
		.collapser {
			-webkit-transition: left 200ms ease-in-out 150ms;
			transition: left 200ms ease-in-out 150ms;
			left: 5px;
			z-index: 24;
		}
	}

	.collapser {
		-webkit-transition: left 200ms ease-in-out 150ms;
		transition: left 200ms ease-in-out 150ms;
		width: 30px;
		height: 30px;
		position: absolute;
		top: 25px;
		left: 385px;
		padding-top: 5px;
		text-align: center;
		font-size: 20px;
		color: rgba(0, 0, 0, 0.4);
		background: #eee;
		z-index: 24;
		border: 1px solid #ccc;
		border-radius: 15px;
		cursor: pointer;
	}

	.sidebar {
		position: sticky;
		top: 0;
		float: left;
		-webkit-transition: width 200ms ease-in-out 150ms;
		transition: width 200ms ease-in-out 150ms;
		width: 400px;
		height: 100vh;
		background: #ddd;
		z-index: 23;
		overflow-y: scroll;
		overflow-x: visible;

		.reducer {
			width: 390px;
			// padding part of children to allow sticky elements to own a background
			> div {
				padding: 20px;
			}
			// no extra padding between sticky and second element
			> *:nth-child(2) {
				padding-top: 0;
			}
			// no extra padding from last element
			> *:last-child {
				padding-bottom: 0;
			}
		}
	}

	.main {
		padding-left: 400px;
		height: 100vh;
		-webkit-transition: padding-left 200ms ease-in-out 150ms;
		transition: padding-left 200ms ease-in-out 150ms;
	}

	// override inputs that error, that are part of a form
	.ui.input.error {
		::placeholder {
			color: white;
			opacity: 0.85;
		}

		> input {
			background-color: rgba(230, 0, 0, 0.2);
			border: 1px solid rgba(130, 0, 0, 0.8);
			box-shadow: none;
		}
	}

	.ui.rating.star {
		pointer-events: none;
	}
}
