@import 'components/colors';
.Login {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 2;
	padding-top: 125px;

	.statusPage {
		text-align: right;
		padding: 6px 0;
		margin: 0 auto;
		width: 400px;
		position: relative;
		animation-name: slideUp;
		animation-duration: 1s;
		animation-timing-function: ease-in-out;
	}

	a {
		font-size: 0.9rem;
		color: $whiteText;
	}

	a:hover {
		text-decoration: underline;
		color: $darkBlueText;
	}

	.countries {
		position: absolute;
		bottom: 5px;
		left: 5px;
		font-size: 1.1rem;

		.white {
			color: #fff;
		}

		.grey {
			color: #aaaaaa;
		}
	}

	.logo {
		text-align: center;
	}

	.logo img {
		width: 500px;
	}

	.links {
		transform-origin: bottom left;
		transform: rotate(-90deg);
		color: #fff;
		position: absolute;
		bottom: 10px;
		right: -255px;
		font-weight: bold;
		font-size: 1.1rem;

		a {
			font-size: 1.1rem;
		}
	}

	.background-crossfade {
		> * {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: -2;

			&.blue-hue-hack {
				z-index: -1;
				background: $blueContainer;
				opacity: 0.75;
			}
		}

		img {
			transition: opacity 2s ease-in-out;

			animation-name: backgroundFadeInOut;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
			animation-duration: 45s;
			&:nth-of-type(1) {
				animation-delay: 36s;
			}
			&:nth-of-type(2) {
				animation-delay: 27s;
			}
			&:nth-of-type(3) {
				animation-delay: 18s;
			}
			&:nth-of-type(4) {
				animation-delay: 9s;
			}
			&:nth-of-type(5) {
				animation-delay: 0s;
			}
		}

		// see http://css3.bradshawenterprises.com/cfimg/#cfimg3 for how to calc timing
		@keyframes backgroundFadeInOut {
			0% {
				opacity: 1;
			}
			16% {
				opacity: 1;
			}
			20% {
				opacity: 0;
			}
			96% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}
	}
}
