@import 'components/colors';
.Subscriptions {
	$hoverfilter: invert(10%) grayscale(30%);
	.VTRow.hidden {
		.VTCell {
			background-color: #f7f7f7;
			color: #9b9b9b;
		}
	}

	.VTHeaderCell,
	.VTCell {
		&.type {
			justify-content: center;
		}

		&.share {
			.SelectColleague.disabled {
				opacity: 0.8 !important;
				border-color: transparent;
				background-color: transparent;

				.icon.delete,
				.icon.clear,
				.icon.dropdown {
					display: none;
				}
			}

			// in order for menu to work correctly
			overflow: inherit;

			.VTCellContent {
				overflow: inherit;
			}
		}

		&.actions {
			// use fixed widths for Buttons to simplify their positioning inside Table
			.showHide {
				&.hidden {
					background-color: $blueButton !important;
					color: $whiteText !important;

					&:hover {
						filter: $hoverfilter;
					}
				}
			}
		}
	}

	.VTCell {
		&.type {
			.VTCellContent {
				width: 100%;
				text-align: center;
			}
		}

		&.name {
			.VTCellContent {
				text-overflow: unset;
				overflow-x: auto;
			}
		}
	}

	.ui.basic.buttons {
		.displayQueries {
			background-color: $blueButton !important;
			color: $whiteText !important;
			&:hover {
				filter: $hoverfilter;
			}

			&.hidden {
				background-color: $lightGrayButton !important;
				color: $grayText !important;
			}

			&.notenabled,
			&.hidden.notenabled {
				color: $darkGrayText !important;
				background-color: $blueButtonNotEnabled !important;
			}
		}
	}
}
