.UserDropdown {
	.white-text {
		color: #fff;
		padding: 20px 20px;
		margin-bottom: 0;
		font-size: 17px;

		.icon {
			font-size: 17px;
		}

		&.full {
			display: block;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&.small {
			display: none;
		}
	}

	.dropdown {
		display: block;
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0.2);
	}

	&.collapsed .white-text {
		&.full {
			display: none;
		}

		&.small {
			display: block;
			margin-top: 0;
		}
	}
}
