@import 'components/fonts';
.Navigation {
	font-family: $standardFontFamily;

	a,
	a:focus {
		display: block;
		color: white !important;

		padding: 20px !important;
		font-size: 17px;

		&:hover {
			background-color: rgba(0, 0, 0, 0.2);
		}
		&.active {
			background-color: rgba(0, 0, 0, 0.4);
		}

		.icon {
			margin-right: 25px !important;
		}
	}

	&.collapsed .navigation-label {
		display: none;
	}
}
