.Success {
	width: 600px;
	margin: 0 auto;
	text-align: center;
	margin-top: 200px;
}

.Success .icon-hero {
	margin-bottom: 50px;
}

.Success .body {
	text-align: left;
}

.Success .actions {
	width: 300px;
	margin: 50px auto;
}

.Success .button {
	margin-bottom: 20px;
}
