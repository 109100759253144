.ResetForm {
	width: 400px;
	min-width: 15vw;
	max-width: 30vw;

	margin: auto;
	margin-top: 30px;
	margin-bottom: 2rem;
	.button,
	.button:active,
	.button:focus,
	.button:hover {
		color: #fff;
		margin-top: 30px;
	}
	.password-reset-message {
		.header {
			padding-bottom: 10px;
			font-size: 1.05rem !important;
		}
		span {
			font-size: 0.9rem !important;
		}
	}
	.tip {
		white-space: pre-wrap;
		font-size: 0.8rem;
		color: rgb(103, 121, 141);
		font-weight: 600;
		margin: 25px;
	}
}
