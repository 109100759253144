.CreateLeafletForm {
	.overlay {
		position: absolute;
		top: 0;
		height: 100vh;
		width: 100%;
		margin-left: -20px;
		background-color: rgba(196, 196, 196, 0.7);
	}
	.MaximumReached {
		color: dimgray;
		margin: 1.5rem;
		top: 70px;
		opacity: 0.9;
	}
	.MaximumReached h3 {
		text-align: center;
	}
}
