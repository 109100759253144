.Toolbar {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	height: 65px;

	.ui.buttons {
		&:nth-child(1) {
			margin-right: 7px;
		}
		&:nth-child(2) {
			margin-right: 10px;
		}
		&:nth-child(3) {
			margin-right: 10px;
		}
		&:last-child {
			margin-right: 0 !important;
		}

		a .ui.button {
			// e.g. csv button
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	.ui.icon {
		cursor: pointer;
	}
	.ui.header {
		flex: 1;
		overflow-x: hidden;
		text-overflow: ellipsis;
		margin-top: 8px;
		margin-left: 5px;
		font-size: 1.25rem;
	}
	.right-side {
		flex: 0;
		margin-left: 20px;
	}
	.ui.header,
	.right-side {
		white-space: nowrap;
	}

	.actionBar {
		flex: {
			grow: 0;
			shrink: 1;
		}
		white-space: nowrap;
		margin-left: 20px;
	}

	.paging {
		flex: {
			grow: 1;
			shrink: 0;
		}
	}
	.ui.dropdown {
		z-index: 21;
	}

	.invisible {
		visibility: hidden; // invisible buttons just hidden to assure that toggle button keeps position
	}

	.paging.invisible {
		display: none; // nav bar should not occupy space when invisible in order to make sure that enough space for header
	}
}
