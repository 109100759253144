@import 'components/colors';

.EventDetail.isShowInput {
	@import 'components/colors';

	.error:not(.BlocksInput) input,
	.ownError input {
		background-color: $errorInput !important;
	}

	.isUpdated:not(.BlocksInput):not(.error):not(.ownError):not(.ui.dropdown) input {
		background-color: $changedInput !important;
	}
	.isUpdated.ui.dropdown .label {
		background-color: $changedInput !important;
	}

	textarea.isUpdated,
	.isUpdated.BlocksInput:not(.error),
	.isUpdated.picture-uploader {
		background-color: $changedInput !important;
	}

	.heading {
		display: flex;

		h1 {
			flex: {
				grow: 1;
			}
			.ui.input.name {
				width: 80%;
			}
			.mandatory input {
				border: 1px solid $darkGrayContainer;
				border-radius: 6px;
				z-index: 1;
				&:hover {
					border-color: $borderHover;
					&:focus {
						border: 1px solid $borderHoverFocus;
					}
				}
				&:focus {
					border: 1px solid $borderFocus;
				}
			}
		}
	}

	.InfoBlock {
		.mandatory:not(.dateInputTo) input {
			border: 1px solid $darkGrayContainer;
			border-radius: 6px;
			&:hover {
				border-color: $borderHover;
				&:focus {
					border: 1px solid $borderHoverFocus;
				}
			}
			&:focus {
				border: 1px solid $borderFocus;
			}
		}
		.ui.input {
			width: 100%;
		}
		.field:not(:first-of-type) {
			margin-top: 10px;
		}

		.ui.selection {
			width: 100%;
			a {
				display: inherit !important;
			}
			&.mandatory {
				border: 1px solid $darkGrayContainer;
				border-radius: 6px;
				&:hover {
					border-color: $borderHover;
					&:focus {
						border: 1px solid $borderHoverFocus;
					}
				}
			}
			&:focus {
				border: 1px solid $borderFocus;
			}
			&.isUpdated.dropdown {
				background-color: $changedInput !important;
			}
		}

		.CustomDateInput:last-of-type {
			margin-bottom: 0px;
		}

		.BlocksInput {
			&.error {
				background-color: $errorInput;
			}
			&.isUpdated .block {
				&.error input {
					background-color: $errorInput !important;
				}
			}
		}
	}

	.ui.selection.dropdown.rating {
		min-width: 130px !important;
		min-height: 45px;
		padding: 7.5px !important;
		padding-top: 14px !important;
	}

	.ui.input.name {
		margin-top: 5px;
		input {
			padding: 5px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.BlocksInput,
	.ui.selection.RatingInput {
		border: {
			color: $borderGray !important;
			style: solid;
			width: 1px;
		}
	}

	.ui.input + .ItemArrayList {
		margin-top: 10px;
	}
}
