.GenericWorkflow {
	th.leaflet,
	td.leaflet {
		min-width: 50px;
		max-width: 50px;
		text-align: center;
	}
	// allow naviation buttons to fit when for #10,000 of 10,000
	.sticky {
		.ui.card > .content,
		.ui.cards > .card > .content {
			padding: 0.8em 0.8em;
		}
	}
}
