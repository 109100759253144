.FieldSwitcher {
	.menu.transition.left.visible {
		border-top: 10px solid #fff;

		.menu.transition.scrolling {
			max-height: 80vh;
			width: 100% !important;
		}
	}

	.search.input {
		padding: 0 !important;
		margin: {
			top: 0 !important;
			bottom: 10px !important;
			left: 10px !important;
			right: 10px !important;
		}
	}
	.selected {
		background-color: #999999;
		font-weight: bold !important;
	}

	i.icon {
		// prevents dropdown items from "losing 2 px" when checkmark icon is rendered/shown
		height: inherit;
	}
}
