.EventCarousel {
	position: absolute;
	top: 0;
	background-color: white;
	display: inline-block;
	z-index: 22;

	/* cancels FullLayout padding  */
	width: calc(100% + 2 * 20px);
	height: calc(100% + 2 * 20px);
	margin: -20px 0 0 -20px;
	.Toolbar {
		background-color: #eeeeeec7;
		padding: {
			left: 15px;
			right: 5px;
			top: 15px;
			bottom: 0;
		}
	}
}
