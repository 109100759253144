@import 'components/colors';

.Actions {
	display: inline-block;
	padding-right: 4px;

	.ui.dropdown.icon {
		padding: {
			right: 2.8em !important;
			left: 0.8em !important;
		}
		margin-right: 0 !important;
		> .icon {
			width: 2.57142857em;
			background-color: rgba(0, 0, 0, 0.05);
			position: absolute;
			height: 100%;
			line-height: 1;
			top: 0;
			left: auto;
			right: 0;

			&:before {
				display: block;
				position: absolute;
				width: 100%;
				top: 50%;
				text-align: center;
				-webkit-transform: translateY(-50%);
				transform: translateY(-50%);
			}
		}
		.menu {
			width: 240px;

			& > .item.action-item,
			> .ToCustomLeaflet {
				padding: 10px !important;
				margin: 0 !important;
			}
			> .ToCustomLeaflet {
				padding-bottom: 5px !important;
			}
			> .item.action-item {
				padding: {
					top: 5px !important;
					bottom: 5px !important;
				}
			}

			.item.action-item:last-of-type {
				margin-bottom: 5px !important;
			}

			& > .item.action-item .button {
				width: 100% !important;
				text-align: left;
			}
		}
	}

	&.stagingSelectedAction {
		border: {
			style: solid;
			color: #dededf;
			width: 1px;
			radius: 5px;
		}
		&:hover {
			border-color: #bebebf;
		}

		.ui.dropdown.icon {
			background-color: transparent;
			width: 350px;
			border: {
				top-right-radius: 0;
				bottom-right-radius: 0;
			}
			color: $lightGrayText;
			font-weight: unset;
			&.stageAction {
				color: $darkGrayText;
				font-weight: bold;
			}
			.dropdown.icon {
				color: $grayText;
				background-color: $lightGrayButton;
			}
		}
		.clearButton {
			margin-top: 0;

			margin-right: -4px;
			padding: {
				left: 7px;
				right: 7px;
			}
			border: {
				top-left-radius: 0;
				bottom-left-radius: 0;
			}
			background-color: $grayButton !important;
			&:hover {
				background-color: $grayButtonHover !important;
			}
			.icon {
				margin: 0 !important;
			}
		}
	}
}
