.SetListToolArea {
	.right {
		float: right;
		.label {
			display: inline-block;
			margin-right: 10px;
			vertical-align: super;
			font-size: small;
		}
	}
}
