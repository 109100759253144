@import 'components/colors';
.SubscribeForm.ui.form {
	.field button.saveSearch.ui.button {
		border: 1.5px solid transparent !important;
		&:focus {
			border-color: $borderGrayFocus !important;
		}
	}

	.formFieldNameInput input {
		border: 1px solid transparent !important;
		&:focus-within {
			border-color: $borderFocus !important;
		}
	}
}
