@import 'components/colors';

.LockButton.ui.button {
	background-color: $lightGrayButton;
	&.disabled {
		pointer-events: inherit !important; // showing popup despite disabled
	}

	&.lockedByMe {
		color: $whiteText;
		background-color: $greenButton;
		&:hover {
			background-color: $greenHoverButton;
		}

		.icon {
			transform: rotate(-20deg); // use another visual variable to code lockedByMe (color blindness)
		}
	}
	&.lockedByOtherUsers {
		color: $whiteText;
		background-color: $redButton;
		&:hover {
			background-color: $redHoverButton;
		}
	}
}
