@import 'components/colors';
.ExportInbox {
	height: 100%;

	.exports {
		width: 600px;
	}

	.header .icon {
		color: $orangeIcon;
	}
}
