@import 'components/colors';

.Submit.ui.button {
	width: 110px;
	height: 36px;
	background-color: $lightBlueButton !important;
	padding: 0;
	.save.icon {
		font-size: 18px;
	}
	span {
		margin-left: 5px;
	}
	&:hover {
		background-color: $blueButtonEnabledHover !important;
	}
	&.disabled {
		pointer-events: inherit !important; // showing popup
	}
}
