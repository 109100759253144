@import 'components/colors';
.EventStateQuickFilter {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	button.ui.button {
		margin: 0;
		height: 30px;
		padding-top: 7px;
		border-radius: 5px;

		&.enabled {
			color: $whiteText;
			background-color: $blueButtonEnabled;
			&:hover {
				background-color: $blueButtonEnabledHover;
			}
		}
		&.notenabled {
			color: black;
			background-color: $blueButtonNotEnabled;
			&:hover {
				background-color: $blueButtonNotEnabledHover;
			}
		}
		background-color: white;
		.icon {
			margin: auto !important;
		}
	}
}
