.Generic {
	height: 100%;
	position: relative;

	.ui.message {
		position: sticky;
		margin: 10px auto;
		width: calc(100% - 2 * 10px);
	}

	#focused {
		background-color: rgba(119, 181, 255, 0.3) !important;
	}
	#focused.selected {
		background-color: rgba(63, 114, 175, 0.6) !important;
	}
}
