@import 'components/colors';
@import 'components/fonts';
.swal2-popup {
	box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.2), 1px 3px 15px 2px rgba(0, 0, 0, 0.2);
	width: 80%;
	max-width: 600px;
}

.swal2-header {
	padding: 0;
	position: relative;
}

.swal2-title {
	font-family: $buttonFontFamily;
	font-size: 1.875em !important;
	font-weight: 700 !important;
	width: 100%;
	padding-bottom: 10px !important;
	border-bottom: 1px solid #e0e1e2;
	color: rgba(0, 0, 0, 0.85) !important;

	img {
		width: 60px;
		height: 60px;
	}
	p {
		padding-top: 5px;
	}
}

.swal2-content {
	font-family: $standardFontFamily;
	text-align: left !important;
	padding: 10px 0px !important;
	font-style: normal !important;
	width: 100%;
}

.swal2-actions {
	border-top: 1px solid #e0e1e2;
	width: 100%;
	padding-top: 10px;
	font-size: 1rem !important;
	justify-content: flex-end !important;
}

.swal2-styled {
	margin: 0 0.25em 0 0;
	border-radius: 2px;
	background-color: #e0e1e2;
	font-size: 1rem !important;
	font-weight: 700 !important;
	border-radius: 4px;
	font-family: $buttonFontFamily;
}

.swal2-cancel {
	background-color: #e0e1e2 !important;
	color: rgba(0, 0, 0, 0.6) !important;
}

.swal2-confirm {
	background-color: $blueButton !important;
}

.swal2-popup .swal2-styled:focus {
	box-shadow: none !important;
}

body.swal2-shown {
	overflow-y: scroll;
}

.fadeUp {
	animation-name: fadeInUp;
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
}

/* Adapt semantic ui modal to sweet alert */

.ui.page.modals.dimmer.transition.visible.active {
	z-index: 25;
	background-color: rgba(0, 0, 0, 0.6);
}

.ui.modal {
	position: relative;

	> .actions {
		width: 96%;
		margin-left: 2%;
		padding: 1rem 0.2rem;
	}

	> .header {
		width: 96%;
		margin-left: 2%;
		padding: 1.25rem 0.5rem;
		padding-bottom: 10px;
		border-bottom: 1px solid #e0e1e2;
		font-size: 1.875em !important;
	}

	.ui.positive.button {
		background-color: $greenButton;
	}
	.ui.positive.button:hover {
		background-color: $greenHoverButton;
	}
	.ui.positive.button:active {
		background-color: $drakGreenButton !important;
	}
	.ui.loader:before {
		border-color: rgba(0, 0, 0, 0.1);
	}
	.ui.loader:after {
		border-color: #767676 transparent transparent;
	}

	.ui.large.active.centered.inline.loader {
		top: calc(50% - 24px);
	}
}
