.FullLayout {
	.dimmable.dimmable {
		height: 100%;
		overflow: inherit;
		.ui.active.transition.visible.inverted.dimmer {
			// counter margin on FullLayout
			top: -20px !important;
			left: -20px !important;
			width: calc(100% + 40px);
			height: calc(100% + 40px);
		}
	}
}
