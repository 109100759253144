@import 'components/colors';

.Action {
	font-weight: bold;
}

.Action.enabled:hover {
	cursor: pointer;
	text-decoration: underline;
}

.Action .red {
	color: $redText;
}

.Action .green {
	color: $greenText;
}
