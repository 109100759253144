@import 'components/colors';
.EventDetail {
	// 66px = height of Toolbar
	height: calc(100% - 66px);
	width: 100%;

	padding: 0 25px;

	.wrapper {
		height: 100%;
	}

	.ui.secondary.pointing.menu.lastPaneDisabled > a:last-child {
		pointer-events: none;
		color: $grayText;
	}

	.heading {
		display: flex;
		align-items: center;
		height: 62px;
		padding-top: 18px;

		.ui.rating {
			margin-right: 15px;
			padding-bottom: 4px;
		}
	}

	.id-header {
		margin: {
			top: 15px;
		}

		display: inline-block;
	}

	.single_line {
		height: 1.5em;
		overflow: hidden;
		max-width: 95%;
	}

	hr {
		margin: 0;
		opacity: 0.2;
	}

	.adress_row {
		height: 80px !important;
	}

	.ui.table {
		margin: -1px 0 0 0;
	}

	.divided {
		display: flex;
		// 62px === height of .heading
		height: calc(100% - 62px);

		& > * {
			width: 50%;
			flex-grow: 1;
			flex-shrink: 1;
		}

		.preview {
			.tab {
				// 65px === (tab-) menu height+margin
				height: calc(100% - 56px);
				padding-bottom: 5px;
			}

			.ui.pointing.secondary.menu {
				height: 0;
			}
		}

		::-webkit-scrollbar-track {
			background: #fff;
		}

		::-webkit-scrollbar-track:hover {
			background: #eee;
		}

		.scroll_frame {
			overflow-y: scroll;
			overflow-x: hidden;
			max-width: 600px;
			padding-left: 10px;
			// should match with padding/margin on tab
			margin-bottom: 5px;

			.InfoBlock:first-child {
				margin-top: 5px;
			}

			.InfoBlock a {
				display: block;
				max-width: 350px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}

	.first_col {
		width: 120px !important;

		&.NO_INPUT {
			filter: opacity(0.6);
		}
	}

	.ui.segment {
		margin: 0;
		padding: 0;
		border: 0 !important;
		box-shadow: none !important;
	}

	.ui.table td {
		padding: 9px 15px;
	}

	.InfoBlock .redirect-icon {
		margin: 5px;
		color: $blueText;
		&:hover {
			cursor: pointer;
		}
	}

	.ui.input.address:not(:first-of-type) {
		margin-top: 10px;
	}
}
