.Sidebar {
	.logo {
		display: block;
		cursor: pointer;

		padding: {
			top: 30px;
			bottom: 20px;
		}
		&:hover {
			background-color: rgba(0, 0, 0, 0.2);
		}
		img.meslis-logo {
			height: 20px;
			margin-left: 20px;
		}
		img.meslis-font {
			margin-left: 25px;
			height: 19px;
		}
	}
	.UserDropdown,
	.Collapse {
		margin: 0px;
		position: absolute;
		left: 0px;
		right: 0px;
		color: white;

		.icon {
			margin-right: 25px;
		}
	}

	.UserDropdown {
		text-align: left;
		bottom: 0px;
	}

	.Collapse {
		bottom: 60px;
	}
}
