.Toolbar {
	display: flex;
	justify-content: space-between;
	align-items: flex-end; /* Also flex-start, center and stretch */

	width: 100%;
	margin-bottom: 0;

	.ui.header {
		margin-bottom: 4px;
	}
	.ui.dropdown {
		z-index: 21;
	}

	.right-side > * {
		display: inline-block;
	}

	.ui.icon {
		cursor: pointer;
	}

	.child {
		flex: 1;
		text-align: end;
		padding: 0px 10px;
	}

	.center {
		text-align: center;
	}
}
