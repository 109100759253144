$verticalGap: 14px;
.EventStateInfoInput {
	margin-bottom: $verticalGap;
	.userIcon {
		background-color: white;
		margin: {
			bottom: $verticalGap;
			right: 0;
		}
		padding: 0;
		border: {
			bottom-right-radius: 0;
			top-right-radius: 0;
		}
		.icon {
			margin: {
				top: 12px;
				left: 15px;
				right: 5px;
			}
		}
	}
	.fields {
		margin: 0 !important;
		.field {
			padding: {
				left: 0 !important;
				right: 0 !important;
			}
		}
	}
	.userIcon + .field {
		padding-left: 0 !important;
		.dropdown,
		.disabledLeaflets {
			margin-bottom: $verticalGap;
			padding-left: 5px !important;
			border: {
				bottom-left-radius: 0 !important;
				top-left-radius: 0 !important;
			}
		}
		.disabledLeaflets {
			background-color: white;
			color: #b6b6b6;
			text-decoration: line-through;

			height: 38px !important;
			padding: {
				top: 9px !important;
				left: 16px !important;
			}
			border: {
				bottom-right-radius: 5px !important;
				top-right-radius: 5px !important;
			}
			cursor: default;

			&:hover {
				border: 1px solid #b2b3b3;
			}
		}
	}

	.timestamp .field {
		&:first-child {
			padding: {
				left: 0 !important;
				right: 10px !important;
			}
		}
		&:last-child {
			padding-right: 0 !important;
		}
	}
}
