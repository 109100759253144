.SortExpandMenu {
	height: 30px !important;
	margin: {
		bottom: -15px;
		top: 20px;
		left: -15px;
	}

	display: flex;
	.multisort-group {
		flex-grow: 1;
	}
	.expandIcon {
		flex-grow: 0;
		margin: 0;
		font-size: 1.15em;

		&:hover {
			opacity: 0.7;
			cursor: pointer;
		}
	}
}
