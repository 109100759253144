.GenericPager {
	.new-events {
		float: right;
	}
	.oppositeAlignedFlex {
		display: flex;
		flex-direction: row;
		& > *:first-child {
			text-align: left;
			flex: 1;
		}
		& > *:nth-child(2) {
			text-align: right;
			flex: 1;
			display: block;
		}
	}
}
