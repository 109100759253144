@import 'components/colors';

.group {
	width: 550px !important;
	&-box {
		border: {
			style: solid;
			color: rgba(0, 0, 0, 0.7);
			radius: 20px;
			width: 2px;
		}
		background-color: rgba(0, 0, 0, 0.02);
		padding: 35px;
	}

	&-box.unsubmitted {
		background-color: rgba(255, 187, 0, 0.1);
	}

	.header {
		color: rgba(0, 0, 0, 0.8);
		font-size: 20px !important;
	}
	.unexpanded {
		display: none;
	}

	.expanded .group-content {
		margin-top: 30px !important;
	}

	.button {
		width: 100%;
		&.expander {
			padding: 0 !important;
			margin: {
				left: -18px !important;
				top: 4px !important;
			}
			.icon {
				font-size: 35px !important;
			}
			background-color: rgba(0, 0, 0, 0) !important;
		}
		&.default {
			color: rgba(0, 0, 0, 0.6);
			background-color: $lightBlueButton !important;
			width: 120px;
			position: absolute;
			top: 10px;
			left: 352px;

			&:hover {
				background-color: rgb(215, 215, 215) !important;
			}
			&[disabled='false'] {
				background-color: rgb(230, 230, 230) !important;
			}
			margin-top: 25px !important;
		}
		&.toggle {
			margin: 5px;
			width: 120px;
			background-color: $lightBlueButton;
		}
	}

	.alwaysShown {
		text-align: center !important;
	}

	.grid {
		.column {
			margin-top: 20px !important;
			padding: 0 !important;
		}
	}
	.group-content {
		position: relative;
	}

	.header-reset-group {
		margin: 20px 0 !important;
		color: rgba(0, 0, 0, 0.8);
		.header-reset-content {
			margin: 30px 0 10px 0;
		}
		.content {
			display: flex;
			justify-content: space-between;
			h4 {
				padding-top: 10px;
				font-size: 0.95rem;
			}
		}
	}
}
