.InfoBox {
	margin-top: 8px;
	strong {
		font-size: small;
	}
	.divider {
		margin-top: 0;
		margin-bottom: 5px;
	}
	.description {
		max-height: 65px;
		overflow: auto;
		white-space: pre-line;
	}
}
