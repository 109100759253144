@import 'components/colors';
.ItemArrayList {
	display: inline-flex;
	width: 100%;

	.ui.list {
		margin: 0 5px 0 0;
		flex-grow: 1;
		overflow: hidden;
		.item {
			margin: 1px 0;
			padding-top: inherit !important;
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			&.alignWithSiblings {
				height: 1.4em;
				&.highlight {
					font-weight: 900;
					color: black !important;
				}

				&.notSelected {
					filter: opacity(0.6);
				}
			}
		}
	}

	.expand-icon {
		color: $blueText;
		cursor: pointer;
		white-space: pre;
		margin: 0;
		padding: {
			left: 5px;
			right: 15px;
		}
		&:hover {
			font-weight: 900;
		}
	}
}
