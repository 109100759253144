@import 'components/colors';
@keyframes slideUp {
	0% {
		top: 20px;
		opacity: 0;
	}
	20% {
		top: 20px;
		opacity: 0;
	}
	100% {
		top: 0;
		opacity: 1;
	}
}

@keyframes slideUpBorder {
	0% {
		box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
	}
	20% {
		box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
	}
	100% {
		box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
	}
}

.whitebox {
	margin: 0 auto;
	width: 400px;
	text-align: center;
	animation-name: slideUp, slideUpBorder;
	animation-duration: 1s;
	animation-timing-function: ease-in-out;
	background-color: #fff;
	padding: 50px 30px 15px 30px;
	box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
	border-radius: 5px;

	form {
		padding: 30px 0;
	}

	.avatar {
		font-size: 100px;
		color: $darkBlueText;
		opacity: 0.8;
	}

	.version {
		text-align: right;
		color: #aaaaaa;
		padding-top: 25px;
		font-size: 0.9rem;
	}

	.RequestResetHandler {
		float: left;
		padding: 10px;
		padding-left: 0px;
		background-color: transparent;
		color: $blueText;
		cursor: pointer;
	}

	.RequestResetHandler:hover {
		text-decoration: underline;
		color: black;
	}
}
