@import 'components/colors';
.GenericEventFilters {
	.open,
	.close {
		color: $grayText;
		cursor: pointer;

		text-align: center;
		margin-bottom: -20px;
		position: relative;
		z-index: 3;
		bottom: -5px;
	}

	.EventStateQuickFilter {
		margin-top: 10px;

		button.ui.button.notenabled {
			background-color: $lightGrayButton;
			&:hover {
				background-color: $lightGrayButtonHover;
			}
		}
	}
}
