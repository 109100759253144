@import 'components/colors';
@import 'components/fonts';

.SelectOrganizerModal {
	.checkboxContainer {
		.button,
		.customOrganizer {
			font-weight: bold;
			margin-bottom: 5px;
			&.checked {
				color: $whiteText;
				background-color: $blueButtonEnabledHover;
				&.shown {
					background-color: $blueButtonEnabled;
				}
			}
			&:not(.checked) {
				color: black;
				background-color: $lightGrayButton;
				&.shown {
					background-color: $blueButtonNotEnabledHover;
				}
			}
			&.invalid {
				background-color: $redContainer;
			}
		}
	}

	.customOrganizer {
		display: inline-block;
		padding: {
			top: 8px;
			bottom: 9px;
			left: 20px;
			right: 20px;
		}
		margin-top: 5px;
		border-radius: 5px;
		cursor: pointer;
		font-size: 1rem;
		font-family: $buttonFontFamily;
		.input {
			margin-right: 5px;
			margin-left: 5px;

			input {
				padding: 1px;
				padding-left: 11px;
			}
		}
	}

	.organizerContainer {
		margin-right: 70px;
		margin-left: 70px;
		&.opaque {
			filter: opacity(0.6);
		}
		height: 470px; //fix height is necessary otherwise there would be a flicker effect hovering on event
		margin-top: 30px;
		overflow-y: scroll;

		&::-webkit-scrollbar {
			display: none; // do not show scollbar which occures in rare cases, scrolling by wheel still possible
		}

		.first_col {
			width: 120px !important;
		}
	}
	.ok.button {
		&:not(.disabled) {
			background-color: $blueButton !important;
			color: white !important;

			&:hover {
				background-color: $blueButtonHover !important;
			}
		}
	}
}

.SelectOrganizerIcon {
	margin-left: 0.7em !important;
	color: $blueText;
	&:hover {
		filter: opacity(0.7);
	}
}
