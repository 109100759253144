@import 'components/colors';
.InfoPopup {
	white-space: nowrap;

	.infoIcon.info.icon {
		color: $blueText;
		cursor: default;
		&.select-organizer {
			margin-left: -5px;
			color: $blueInfoText;
		}
	}
}

.InfoPopup-Message {
	// not inside InfoPopup in DOM
	white-space: pre-line;
}
