@import 'components/colors';
.LeafletBox {
	h3 {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	tr {
		.icon {
			opacity: 0;
			transition: opacity 0.1s ease-in-out;
		}
	}

	tr:hover {
		.icon:not(:disabled) {
			opacity: 1;
			cursor: pointer;
		}
	}

	.shared {
		display: inline-block;
		float: right;
	}

	.open,
	.close {
		color: grey;
		cursor: pointer;
		text-align: center;
		margin-bottom: -18px;
		position: relative;
		z-index: 3;
	}

	.leafletDescription {
		margin: 8px 0 0 0;
	}
	.createdAt {
		margin-top: 7px;
		margin-bottom: -5px;
		&.noBox div.ui.label {
			border: none !important;
			padding-left: 0;
			background-color: transparent;
		}
	}

	hr {
		opacity: 0.2;
	}

	.ui.small.table {
		margin-top: 0.5rem;
	}

	.ui.segment {
		&.current {
			border-top: {
				width: 6px !important;
				style: solid !important;
			}
			margin-top: -4px;
			&:not(.conflict) {
				border-top-color: $thickBlueBorder !important;
				background-color: $blueShimmerContainer !important;
			}
			&.conflict {
				border-top-color: $thickRedBorder !important;
				background-color: $redShimmerContainer !important;
			}
		}
		&:not(.current).conflict {
			border-top-color: $thickLightRedBorder !important;
		}
		&.conflict {
			.filledLeaflet {
				.items,
				.leafletDescription {
					color: $redText !important;
				}
			}
		}
	}
}
