@import 'components/colors';
.refreshButton {
	.sync.alternate.icon {
		width: 1em;
		height: 1em;
		line-height: 1em;
	}
	.sync.alternate.icon::before {
		width: 1em;
		height: 1em;
		line-height: 1em;
	}

	&.ui.icon:not(.disabled).button {
		background-color: $darkBlueButton;
		color: white;
	}
	
	.rotate {
		// blink animation
		@keyframes blink {
			0% {
				color: $darkBlueButton;
			}
		}
		animation-name: blink;
		animation-duration: 1.5s;
		animation-timing-function: ease-in;
		animation-iteration-count: 1;
		animation-direction: normal;
		color: white;
	}
}
