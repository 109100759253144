@import 'components/colors';
.FilterForm,
.EventStateQuickFilter {
	.BlocksInput {
		border: 1px solid transparent;
		&:hover {
			border-color: $borderHover;
		}
	}

	//assuring that inputs are always marked when focused
	div.ui.input input,
	.ui.dropdown,
	.BlocksInput,
	.block-wrapper {
		border: 1px solid transparent;
		&:focus-within {
			border: 1px solid $borderFocus;
		}
	}

	.button {
		border: 1.5px solid transparent;
		&:focus {
			border-color: $borderGrayFocus;
		}
	}

	.error > input[type='text'],
	.error > input[type='text']:focus,

	.error input,
	.ownError input {
		background-color: $errorInput !important;
	}
	.error.ownError input {
		background-color: $ownErrorInput !important;
	}
	
	.BlocksInput.error,
	.GenericSelect.ui.selection.dropdown.error {
		background-color: $redContainer;
		border: 1px solid $borderError;
	}
}
