.ColoredIdSwitcher {
	display: inline;
	svg {
		width: 14px;
		height: 14px;
		margin-left: 2px;
		rect {
			width: 100%;
			height: 100%;
		}
		circle {
			cx: 50%;
			cy: 50%;
			r: 42%;
		}

		filter: brightness(80%);
		opacity: 0.6;
		&:hover {
			filter: brightness(40%);
			opacity: 0.8;
		}
	}

	span.streched {
		display: inline-flex !important;
		width: 210px !important;
		align-items: stretch !important;
		.idx {
			flex-grow: 1 !important;
		}
	}

	.forward-button,
	.backward-button {
		width: 10px !important;
		height: 17px !important;
		padding: 0 !important;
		background-color: transparent;
		.icon {
			display: inline;
			color: black;
			font-size: 1.2em;
		}
	}
	.forward-button {
		margin-left: 4px !important;
	}
	.backward-button {
		margin-right: 1px !important;
	}
}
