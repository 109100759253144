@import 'components/colors';
.ChangePasswordForm {
	.button,
	.button:active,
	.button:focus,
	.button:hover {
		color: #fff;
		margin-top: 30px;
	}
	.password-change-message {
		.header {
			padding-bottom: 10px;
			font-size: 1.05rem !important;
		}
		span {
			font-size: 0.9rem !important;
		}
	}
	.tip {
		white-space: pre-wrap;
		font-size: 0.8rem;
		color: $lightBlueText;
		font-weight: 600;
		margin: 25px;
	}
}
