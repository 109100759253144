@import 'components/colors';
.toInput {
	button {
		background-color: $blueButton !important;
		&:hover {
			background-color: $blueButtonHover !important;
		}
		i {
			color: $whiteText;
		}
		margin: 0 !important;
		padding: 0 !important;
	}
	&.range button {
		border: {
			top-right-radius: 0 !important;
			bottom-right-radius: 0 !important;
		}
	}
	input {
		border: {
			top-left-radius: 0 !important;
			bottom-left-radius: 0 !important;
		}
	}
}

.operatorInput {
	span {
		margin-right: 20px !important;
	}
}
