@import 'components/colors';

$innerGap: 6px;
$iconHeight: 17px;
$iconWidth: 30px;

.BlocksInput {
	position: relative;
	background-color: $whiteContainer;
	margin-bottom: 14px;
	border-radius: 4px;

	padding: $innerGap;
	padding-right: 0;
	padding-bottom: 0;

	.blocks-wrapper {
		width: 90%;
		.block {
			margin-right: $innerGap;
			margin-bottom: $innerGap;

			input {
				padding: 3.5px 4.5px !important;
				background-color: $greyContainer;

				&:hover {
					background-color: $greyContainerHover !important;
					&:not(:focus) {
						border-top-right-radius: 0px !important;
					}
				}

				&:focus-within {
					background-color: $whiteContainer !important;
					& ~ i.icon {
						background-color: $whiteContainer;
					}
				}

				&[value=''] {
					&:not(:focus-within) {
						// unvisible but focusable
						opacity: 0;
						width: 0 !important;
						padding-right: 0 !important;
						padding-left: 0 !important;
						margin-left: -7px !important;
					}
				}
			}

			&:not(:hover) i.icon,
			input[value=''] ~ i.icon,
			input:focus-within ~ i.icon {
				opacity: 0 !important;
			}
			&:hover i.icon {
				opacity: 1;
				z-index: 100;

				transition: {
					duration: 0.15s;
					delay: 0.2s;
					property: opacity;
				}
			}

			i.icon {
				top: -$iconHeight !important;
				width: $iconWidth;
				height: $iconHeight;
				&:nth-of-type(1) {
					right: 0px;
				}
				&:nth-of-type(2) {
					right: $iconWidth;
				}

				border-radius: 0;
				background-color: $greyContainerHover;
				&:hover {
					background-color: $lightGrayButtonHover;
				}
				opacity: 0;
				transition: none;
			}
		}
	}

	> i.deleteAll {
		position: absolute;
		right: 5px;
		top: 50%;
		margin-top: -10px;
	}
}
