@import 'components/colors';
.user-settings-page {
	padding-top: 20px;
	overflow-y: overlay;
	height: 100%;
}

.user-header {
	color: $darkBlueText;
	opacity: 0.8;
	text-align: center;
	font-size: 25px !important;
	margin-bottom: 60px;
	.name {
		margin-top: 10px;
	}
}

.group {
	margin-bottom: 35px;
}

.passwordIcon {
	font-size: 3em !important;
}
