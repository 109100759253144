.CreateEvent {
	padding: 20px;
	height: 100vh;
	.dimmable {
		height: 100%;
	}
	.InfoBlock .CustomDateInput:last-of-type {
		margin-bottom: 0px !important;
	}
}
