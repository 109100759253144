@import 'components/colors';
.ManagementSidebar {
	.header {
		margin-bottom: 10px;
	}
	.EditForm {
		overflow: overlay;
		height: 90vh;
		margin-right: -20px;
		padding-right: 20px;
	}
	.form {
		padding-top: 40px;

		input[readonly],
		input[readonly]:focus {
			background-color: #eee;
			cursor: auto;
		}
	}
	textarea {
		height: 150px;
	}
	.button {
		margin-top: 20px;
	}
	.grey {
		margin-top: 10px;
	}
	.field.hidden {
		display: none;
	}
	.field {
		display: block;
	}
	.cancelButton {
		min-width: 50px;
		min-height: 50px;
		margin-left: -70px;
		top: 0;
		color: $whiteText;
		background-color: $lightGrayButton;
		position: absolute;
		text-align: center;
		border-radius: 5px 0 0 5px;
		cursor: pointer;
		.cancel {
			margin: auto;
			padding-top: 15px;
			font-size: large;
		}
	}
	.cancelButton:hover {
		background-color: $lightGrayButtonHover;
	}

	i.dropdown.icon.clear {
		color: $redText;
	}

	.warning {
		color: $redText;
		margin-top: 10px;
		height: 20px;
		margin-bottom: -30px;
	}
}
