.ExportInbox {
	.VTCell.description {
		// override align-items: center; since description might be scrollable
		align-items: baseline;

		// this allows viewing 2 lines at once
		.VTCellContent {
			padding-top: 3px;
			padding-bottom: 3px;
			text-overflow: unset;
			overflow-x: auto;
		}
	}
}
